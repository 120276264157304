.navbar {
    .container {
        padding-bottom: 5px;
        overflow: unset;
    }

    .navbar-nav {
        align-items: center;

        .nav-usp {
            width: 40px;
        }

        .vertical {
            border-left: 1px solid white;
            height: 50px;
        } 
    }

    .nav-link-span {
        padding-left: $navbar-nav-link-padding-x;
        padding-right: $navbar-nav-link-padding-x;
    }

    .navbarDropdownMenuLink {
        margin-bottom: -11px;
        svg {
            position: relative;
            z-index: 1001;
        }
    }

    .nav-item.dropdown:hover {
        #arrow-down {
            stroke: #6b196d;
        }
        .dropdown-menu {
            display: block;
        }
    }

    .dropdown-menu {
        width: 13rem;
        left: 50%;
        transform: translate(-50%);
        background-color: #bb5cca;
        border: 0;
        border-radius: 0.5rem;
        margin-top: -4px;

        .dropdown-divider {
            width: 22%;
            margin: 0.5rem 41px;
            border-top: 2px solid #dcaee4;
        }

        a.dropdown-item {
            color: #e4c2eb;
            padding: 0.25rem 1.25rem;

            span {
                font-size: 20px;
                color: #bb5cca;
            }
        }

        a.dropdown-item:hover {
            color: #fff;
            background-color: #bb5cca;

            span {
                color: #fff;
            }
        }
    }
}

.nav-button {
    .nav-link {
        background: #bb5cca;
        border-radius: 25px;

        .nav-link-span {
            color: #f9f0fa;
        }
    }
}

.page_8, .page_9, .page_10 {
    .navbar-nav {
        display: flex;
    }
}