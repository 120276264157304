.jumbotron {
    width: 100%;
    margin-bottom: 0;
    padding-top: 130px;
    padding-bottom: 80px;
    background-color: $brand-app;

    .container {
        padding: 0 10px;
    }

    .content {
        color: #fff;
        margin-top: 20px;
        z-index: 10;

        h1 {
            font-size: 40px;
            line-height: 1.3;
            font-weight: 700;
            font-family: $font-family-base;
            margin-bottom: 30px;

            -moz-hyphens: auto;
            -o-hyphens: auto;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;

            span {
                background-color: $brand-app;
                white-space: pre-wrap;
            }
        }

        h2, h1 span span {
            -moz-hyphens: auto;
            -o-hyphens: auto;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto; 
        }

        h2 {
            font-size: 28px;
            line-height: 1.2;
            font-weight: 300;
            font-family: $font-family-base;
            margin-bottom: 30px;
        }

        p {
            font-size: 17px;
            line-height: 1.3;
            font-weight: 400;
            font-family: $font-family-base;
            margin-bottom: 30px;
        }

    }

    .jumbotron-logo {
        max-width: 100%;
        margin-top: 10px;

        object {
            width: 80%;
            display: block;
            margin: auto;
            padding-top: 20px;
        }

        img {
            width: 100%;
            display: block;
            margin: auto;
            padding-top: 20px;
        }

        p {
            font-size: 14px;
            text-align: right;
        }
    }
}

.page_3 {
    .cont-code {
        margin-top: 160px;
    }
}

.page_7 {
    .jumbotron {
        .content {
            h2 {
                span {
                    background-color: $brand-app;
                    white-space: pre-wrap;
                }
            }
        }
    }
}

@media only screen and (min-width : 370px) {
    .jumbotron {
        .container {
            padding: 0 10%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .jumbotron {
        .container {
            padding: 0;
        }

        .content {
            h2 {
                font-size: 30px;
            }
        }

        .jumbotron-logo {
            margin-top: 10px;

            object {
                width: 60%;
            }

            img {
                width: 100%;
            }
        }
    }

    .page_7 {
        .jumbotron {
            .container {
                .jumbotron-logo {
                    object {
                        width: 80%;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .jumbotron {
        min-height: 520px;

        .container {

            padding-left: 40px;
            padding-right: 40px;

            .content {
                margin-top: 55px;

                a {
                    margin-left: 0;
                }

                h2 {
                    max-width: 80%;
                }
            }

            .jumbotron-logo {
                position: absolute;
                left: 60%;
                width: 300px;
                margin: 0 auto;

                object {
                    width: 100%;
                    margin: auto;
                    padding-top: 0px;
                    padding-left: 20px;
                }

                img {
                    width: 100%;
                    max-width: 100%;
                    margin: auto;
                    padding-top: 0px;
                    padding-left: 20px;
                }
            }
        }
    }

    .page_6 {
        .jumbotron {
            .container {
                .content {
                    margin-top: 30px;

                    h1 {
                        font-size: 32px;
                    }

                    h2 {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .page_7 {
        .jumbotron {
            .container {
                .jumbotron-logo {
                    width: 640px;
                    left: 32%;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .jumbotron {
        min-height: 620px;

        .container {
            .content {
                margin-top: 50px;

                h1 {
                    font-size: 59px;
                }

                p {
                    font-size: 20px;
                }
            }

            .jumbotron-logo {
                width: 350px;
            }
        }
    }

    .page_6 {
        .jumbotron {
            .container {
                .content {
                    margin-top: 50px;

                    h1 {
                        font-size: 50px;
                    }

                    h2 {
                        font-size: 28px;
                    }
                }
            }
        }
    }

    .page_7 {
        .jumbotron {
            .container {
                .jumbotron-logo {
                    width: 720px;
                    left: 40%;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .jumbotron {
        min-height: 670px;

        .container {
            .content {

                h1 {
                    font-size: 66px;
                    letter-spacing: 1px;
                }
            }

            .jumbotron-logo {
                width: 400px;
            }
        }
    }

    .page_6 {
        .jumbotron {
            .container {
                .content {
                    h1 {
                        font-size: 66px;
                    }
                }
            }
        }
    }

    .page_7 {
        .jumbotron {
            .container {
                .jumbotron-logo {
                    width: 750px;
                    left: 42%;
                }

                .content {
                    h1 {
                        padding-right: 30px;
                    }
                }
            }
        }
    }
}


// hero to replace jumbotron over time

.hero {
    width: 100%;
    margin-bottom: 0;
    padding-top: 90px;
    @include padding-bottom-half;
    background-color: $brand-app;

    .title {
        padding-top: 40px;
        z-index: 1;

        h1 {
            color: #fff;
            font-size: 40px;
            line-height: 48px;
            font-weight: 700;
            font-family: $font-family-base;

            span {
                span {
                    -moz-hyphens: auto;
                    -webkit-hyphens: auto;
                    -ms-hyphens: auto;
                    hyphens: auto;
                }

                br {
                    display: block;
                }
            }
        }
    }

    .content {
        z-index: 1;

        img {
            width: 30px;
        }

        a {
            img {
                padding-top: 0px;
            }
        }

        a.rezept-button {
            display: flex;
            align-items: center;
            text-align: left;
            background-color: $hero-rezept-button-bg;

            p {
                font-size: 16px;
                color: $hero-rezept-button-font;
                margin-bottom: 0;
                margin-left: 15px;
            }
        }
    }

    object,img {
        width: 80%;
        display: block;
        margin: auto;
        padding-top: 20px;
    }

    p {
        color: #fff;
        font-size: 23px;
        line-height: 1.1;
        font-weight: 400;
        font-family: $font-family-base;
        margin-bottom: 30px;
    }

    .container {
        padding: 0 10%;
    }
}

.page_5 {
    .hero {
        .content {
            img {
                width: auto;
                margin: 0;
                padding: 0 15px 0 0;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: left;
            }
        }

        img {
            width: 100%;
            display: block;
            margin: 20px auto 0 auto;
            padding-bottom: 30px;
        }

        .title {
            h1 {
                line-height: 1.1;
            }
        }

        p {
            margin-top: 15px;
            font-weight: 300;
        }

        .bob-style {
            @include button-variant($rezept-hero-btn-bg, rgb(145, 145, 145), $brand-heading-dark, $button-bg-light);
            padding: 15px 30px;
            width: 100%;
        }
    }
}

.page_8 {
    .hero {
        padding-top: 120px;

        .container {
            padding-left: 10px;
            padding-right: 10px;
        }

        .title {
            h1 {
                font-size: 40px;
            }
        }

        p {
            font-size: 20px;
        }

        object, img {
            padding-bottom: 30px;
        }

        .bob-style {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    span {
        font-weight: 700;
        color: $brand-name-dark;
    }
}


@include media-breakpoint-up(sm) {
    .hero {
            .title {

                h1 {
                    font-size: 45px;
                    span {
                        span {
                            -moz-hyphens: unset;
                            -webkit-hyphens: unset;
                            -ms-hyphens: unset;
                            hyphens: unset;
                        }

                        br {
                            display: none;
                        }
                    }
                }
            }
    }
}

@include media-breakpoint-up(md) {

    .page_5 {
        .hero {
            .title {
                grid-area: 1 / 1 / 2 / 2;
            }

            img {
                margin-top: 90px;
            }
        }
    }

    .hero {

        .container {
            padding: 0;
        }

        .grid {
            display: grid;
            grid-template-columns: 5fr 90px 3fr;;
            grid-template-rows: auto 1fr;
            grid-column-gap: 0;
            grid-row-gap: 0;
        }

        .title {
            padding-top: 70px;
            grid-area: 1 / 1 / 2 / 3;

            h1 {
                font-size: 48px;
                line-height: 1.28;
            }

            span {
                background-color: $brand-app;
                white-space: pre-wrap;

                span {
                    @include hyphens;
                }
            }
        }

        .content {
            grid-area: 2 / 1 / 3 / 2;
            z-index: 1;

            p {
                font-size: 28px;
            }
        }

        object, img {
            grid-area: 1 / 2 / 3 / 4;
        }
    }

    .page_8 {
        .hero {
            p {
                font-size: 20px;
            }

            object, img {
                padding-bottom: 0px;
            }

            .bob-style {
                margin-left: 0;
            }
        }
    }
}




@include media-breakpoint-up(lg) {

    .page_5 {
        .hero {
            img {
                margin-top: 120px;
            }
        }
    }

    .hero {

        .container {
            padding: 0 40px;
        }

        .grid {
            grid-template-columns: 5fr 145px 2fr;
        }

        .title {
            padding-top: 90px;

            h1 {
                font-size: 59px;
            }
        }


        .content {
            padding-right: 45px;

            p {
                font-size: 30px;
            }
        }
    }

    .page_8 {
        .hero {

            object, img {
                width: 100%;
                grid-area: 1/2/4/4;
            }

            .title {
                grid-area: 1/1/2/2;

                h1 {
                    font-size: 52px;
                    line-height: 1.3;
                }
            }

            .content {
                p {
                    font-size: 20px;
                    line-height: 1.2;
                }
            }
        }
    }

}


@include media-breakpoint-up(xl) {
    .hero {

        .grid {
            grid-template-columns: 2fr 182px 1fr;
        }

        .title {
            padding-top: 100px;

            h1 {
                font-size: 67px;
            }
        }

        .content {
            p {
                font-size: 32px;
            }
        }
    }

    .page_8 {
        .hero {
            .title {
                grid-area: 1/1/2/2;

                h1 {
                    font-size: 56px;
                    line-height: 1.3;
                }
            }

            .content {
                p {
                    font-size: 20px;
                    line-height: 1.2;
                }
            }
        }
    }
}