.filter-green {
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.rezept-form {
    background-color: #fdf9fd;
    margin-bottom: 60px;
    padding: 100px 0px;

    label {
        font-size: 18px;
        line-height: 1.2;
        padding-top: 5px;
    }

    h3 {
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: 700;
        color: $brand-heading-dark;
    }

    #select-attest {
        width: 75%;
        height: 50px;
        color: #000;
        background-color: #fdedff;
        border-radius: 15px;
        border: 0;
        padding: 10px;
    }

    input[type="password"] {
        font: caption;
    }

    .form-control {
        border-radius: 20px;
        background-color: #F9F9F9;
    }

    input::placeholder, select {
        color: #AFAFAF;
        font-style: normal;
    }

    select {
        padding-right: 10px;
    }

    .form-group-file {
        input {
            height: 150px;
            opacity: 0;
            position: absolute;
            margin-top: -110px;
        }

        .form-control.is-invalid {
            border-color: #dc3545;
        }

        .form-control {
            position: relative;
            width: 100%;
            height: 150px;
            border-radius: 20px;
            background-color: #F9F9F9;
            border: 1px solid #ced4da;

            p {
                color: #AFAFAF;
            }

            .error-font {
                color: red;
            }
        }
    }

    .form-group-email, .form-group-email-confirm {
        .glyphicons {
            cursor: pointer;
            margin-left: -45px;
            padding: 5px;
        }

        .glyphicons.glyphicons-eye-slash {
            display: none;
        }
    }

    .form-button {
        span {
            color: $brand-name-light;
            font-weight: 700;
        }
    }

    .line {
        margin-top: 50px;
        background-color: $brand-row-separator;
        width: 100%;
        height: 2px;
    }

    .note-font {
        font-size: 13px;
    }

    p {
        line-height: 1.2;
    }
    .insurencehint {
        margin: 1rem 0;
    }
}