//
// Variables Diff
// --------------------------------------------------


// Brand colors
// -------------------------
// navigation
$brand-app: #6b196d;
$brand-nav-btn: #fff;
$brand-nav-btn-active: #fe8aff;

$brand-custom-sec: #C656C6;
$brand-sec: #FDEDFF;

$brand-hero: #F4F4F4;
$brand-footer: #2a002a;

$brand-heading-dark: #2a002a; // used for headings

$brand-copy : #494949;

$brand-name-dark: #b43db6; // on light backgrounds
$brand-name-light: #FE77FF; // on dark backgrounds

$brand-row-separator: #e6e3d9;
$footer-row-separator: hsla(0,0%,100%,.3);

$portal-box-bg: #6b196d;
$portal-box-font: #fff;
$portal-box-link-color: #1e001e;

// hero
$hero-btn: #fff;
$hero-rezept-button-bg: #2a002a;
$hero-rezept-button-font: #C656C6;

// usp
$usp-bg: #b726b8;
$usp-font: #fff;
$usp-font-title: $usp-font;
$usp-brand-name: #3b0b3b;

// form
$button-label-dark: #3B1A3E;
$button-bg-light: #fff;
$button-border-dark: #3B1A3E; // used for button in patient portal
$input-bg: #fff;
$input-bg-bob: #FDEDFF;
$input-border: #9A9A9A;
$input-placeholder: #B6BBBC;

// image-slider
$mobile-wrapper: $brand-app;
$button-arrow: $brand-app;
$bullet-border: $brand-app;
$bullet-fill: $brand-custom-sec;

// steps
$steps-bg: #fcedff;
$steps-icon-chevron : $brand-app;
$box-bg : $brand-sec;
$box-rezept-btn-span: #C656C6;


// contact cards
$card-left-bg-page1: #2a002a;
$card-left-span-page1: #b726b8;
$card-right-bg-page1: #d183d2;
$card-right-color-page1: #fff;
$card-right-icon-bg-page1: #2a002a;
$card-left-bg-page4: #FDEDFF;

$copy-background: #FDEDFF;

// links
$brand-link: $brand-app;

// passage
$button-passage-label: #fff;

// contact card
$card-left-bg: $brand-app;
$contact-flyer-brand-name: $brand-app;
$card-left-copy: #FFFFFF;

$card-right-bg: $brand-custom-sec;
$contact-info-brand-name: #fff;
$card-right-copy: $brand-heading-dark;

// doctor's page usp

$pro-usp-bg: #FDEDFF;
$webinar-row-separator: #FDEDFF;
$webinar-box-bg: #C656C6;
$dark-box-section-row-separator: #FDEDFF;
$dark-box-bg : $brand-heading-dark;
$subscription-car-left-bg: $brand-heading-dark;
$subscription-car-right-bg: #e6bce7;

// borderline page
$usp-bg-borderline: #B95EC3;
$usp-heading-borderline: $brand-heading-dark;
$text-image-btn-bg-borderline: $brand-heading-dark;
$text-image-btn-font-borderline: #fff;

// syndrom page
$hero-bg-syndrom: #F9EEFE;
$hero-font-syndrom: $brand-heading-dark;
$hero-btn-bg-syndrom: $brand-heading-dark;
$hero-btn-font-syndrom: #ffF;
$info-section-heading-syndrom: $brand-heading-dark;
$info-line-separator-syndrom: #dfc7e8;

// test page
$hero-bg-test: #fff;
$hero-font-test: $brand-heading-dark;
$hero-btn-bg-test: $brand-heading-dark;
$hero-btn-font-test: #FFF;
$usp-bg-test: #F5CFF9;
$usp-font-test: $brand-heading-dark;
$box-section-heading: $brand-heading-dark;
$info-line-separator-test: #dfc7e8;

// rezept page
$rezept-hero-btn-bg: #2a002a;
$rezept-usp-bg: #FDEDFF;
$rezept-usp-font: #2a002a;

// $brand-custom: #219FBF; 
$brand-custom: $brand-app;


// Scaffolding
// -------------------------

$subtext-color: #808080;


// Typography

$font-family-base: 'Jost',
sans-serif;
$font-family-goth: "Gotham SSm A",
"Gotham SSm B",
"Helvetica Neue",
Helvetica,
Arial,
sans-serif;

$font-family-jost: 'Jost',
sans-serif;



$font-size-base: 1.25rem; // 20px;
$font-size-lg: ($font-size-base * 1.2);
$font-size-sm: ($font-size-base * .86);

$h1-font-size: $font-size-base * 1.2;
$h2-font-size: $font-size-base * 1.1;
$h3-font-size: $font-size-base * 1;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.86;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.6; // 24/15 // 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
// $line-height-computed:    floor($font-size-base * $line-height-base); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: $font-family-base;
$headings-font-weight: 400; // 500;
$headings-line-height: 1.6; // 1.2;
$headings-color: inherit;


//nav dark theme

$navbar-dark-color: rgba($white, .8);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color: rgba($white, 1);
$navbar-padding-y: 3px;




// Iconography
// -------------------------

$icon-font-path: "fonts/";
$icon-font-name: "glyphicons-regular"; // "glyphicons-halflings-regular";


// Components
// -------------------------
// Based on 14px font-size and 1.428 line-height (~20px to start)
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$line-height-large: 1.5; // 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5;

// border-radius
//@border-radius-base:        4px;
$border-radius-large: 6px;
//@border-radius-small:       3px;


// jumbotron

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

//margin for spacing

$margin-mobile: 25px;
$margin-tablet: 40px;
$margin-desktop: 60px;

@mixin space($amount) {
    margin: $amount 0;
}

//padding for within elements spacing

$padding-mobile: 25px;
$padding-tablet: 40px;
$padding-desktop: 40px;

@mixin inner-padding($amount, $sel:selector) {

    .#{$sel} {
        padding: 0;
    }

    .#{$sel}~.#{$sel} {
        padding: $amount 0 0 0;
    }
}



// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Form 
$enable-validation-icons: true;

$grid-breakpoints: (xs: 0,
    phone: 480px, // as it was used in bootstrap 3 -> @screen-phone
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1500px);


//patient-portal
$portal-box-bg: $brand-app;
$portal-box-font: #fff;
$portal-box-link-color: #2c464d;