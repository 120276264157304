.project-priovi.page_15 {
    .hero {
        background-color: $hero-bg-test;
        color: $hero-font-test;

        .title {
            h1 {
                color: $hero-font-test;
                font-size: 40px;
            }

            span {
                background-color: unset;
            }
        }

        p {
            color: $hero-font-test;
            font-size: 20px;
        }

        object, img {
            width: 100%;
        }

        .bob-style {
            display: block;
            margin-left: auto;
            margin-right: auto;
            background-color: $hero-btn-bg-test;
            color: $hero-btn-font-test;
        }

    }

    .usps {
        background-color: $usp-bg-test;

        .usp-cards {
            p {
                color: $usp-font-test;
            }
        }
    }

    .arrow {
        margin: 0 auto 60px auto;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-top: 60px solid $usp-bg-test;
        border-bottom: 0 none;
        width: 0;
        height: 0;
    }

    .box-section {
        padding-bottom: 80px;

        span {
            color: $brand-name-dark;
            font-weight: 700;
        }

        h2 {
            color: $box-section-heading;
            font-size: 36px;
            font-weight: 700;
        }

        hr {
            margin-bottom: 60px;
            margin-top: 70px;
            border-color: $info-line-separator-test;
        }

        .bob-style {
            margin: 10px 0 0;
            color: $hero-btn-font-test;
            background-color: $hero-btn-bg-test;
            display: block;
        }
    }

    .cta-white {

        hr {
            margin-bottom: 60px;
            margin-top: 70px;
            border-color: $info-line-separator-test;
        }

        .bob-style {
            margin: 10px 0 0;
            color: $hero-btn-font-test;
            background-color: $hero-btn-bg-test;
            display: block;
        }
    }

    .illustrations {
        background: #fff;
    }
}

@include media-breakpoint-up(md) {
    .project-priovi.page_15 {
        .hero {
            p {
                font-size: 20px;
            }

            .title {
                grid-area: 1 / 1 / 2 / 2;
            }

            object, img {
                padding-bottom: 0px;
            }

            .bob-style {
                margin-left: 0;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .project-priovi.page_15 {
        .hero {
            .title {
                h1 {
                    font-size: 52px;
                    line-height: 1.3;
                }
            }

            .content {
                p {
                    font-size: 20px;
                    line-height: 1.2;
                }
            }
        }

        .box-section {

            h2 {
                font-size: 48px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .project-priovi.page_15 {
        .hero {
            .title {

                h1 {
                    font-size: 56px;
                    line-height: 1.3;
                }
            }

            .content {
                p {
                    font-size: 20px;
                    line-height: 1.2;
                }
            }
        }

        .box-section {

            h2 {
                font-size: 52px;
            }
        }
    }
}