.project-priovi.page_8 {
    .usps {
        background-color: $usp-bg-borderline;
        padding-bottom: 60px;

        h4 {
            color: $usp-heading-borderline;
            font-weight: 700;

            span {
                color: #fff;
            }
        }

        .usp-cards {
            min-height: auto;

            img {
                width: 35px;
            }

            .usp-info {
                min-height: auto;
            }
        }
    }

    .arrow {
        margin: 0 auto 60px auto;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-top: 60px solid $usp-bg-borderline;
        border-bottom: 0 none;
        width: 0;
        height: 0;
    }

    .text-image {
        padding-top: 0;
        padding-bottom: 100px;

        .row {
            display: flex;
            align-items: center;
        }

        img {
            width: 100%;
            margin: auto;
            max-width: 300px;
        }

        .margin-class {
            margin-left: auto;
            margin-right: auto;
        }

        a {
            span {
                font-weight: 700;
                color: $brand-name-light;
            }
        }
    }

    .text-image .bob-style {
        color: $text-image-btn-font-borderline;
        background-color: $text-image-btn-bg-borderline;
        padding: 8px 30px;
        border: solid 1px $text-image-btn-bg-borderline;
        text-decoration: none;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

@include media-breakpoint-up(md) {
    .project-priovi.page_8 {
        .text-image .bob-style {
            margin-left: 0;
        }
    }
}